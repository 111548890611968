// Determine if there are multiple prizes in the sweepstake
export function useSweepstakePrizes(prizes) {
  let prizesComp;
  if (Array.isArray(prizes)) {
    prizesComp = prizes.filter(prize => !prize.reward);
    prizesComp = _SortBy(prizesComp, ['rank',]);
  } else {
    return prizes;
  }
  return prizesComp;
}
